import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types'
import './navitem.scss'

const Navitem = ({ path, label }) => (
    <li>
        <NavLink
            className={({ isActive, isPending }) => (isActive ? 'navitem active' : isPending ? 'navitem pending' : 'navitem')}
            to={path}>{label}
        </NavLink>
    </li>
)

Navitem.propTypes = {
    path: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
}

export default Navitem;