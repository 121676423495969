import { createBrowserRouter as Browser, RouterProvider as Provider } from 'react-router-dom';
import Main from './MainView/MainView';
import Gallery from './GalleryView/GalleryView';
import Contact from './ContactView/ContactView';

const router = Browser([
    {
        path: '/',
        element: <Main />
    },
    {
        path: '/galeria',
        element: <Gallery />
    },
    {
        path: '/kontakt',
        element: <Contact />
    }
])

const Root = () => (
    <Provider router={router} />
)

export default Root;