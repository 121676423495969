import React, { useState } from 'react';
import gallery from '../../data/gallery';
import { FaTimes } from "react-icons/fa";
import './gallery.scss'

const Gallery = () => {
    const [model, setModel] = useState(false)
    const [tempImageSource, setTempImageSource] = useState('')

    const getImage = (imgSrc) => {
        setTempImageSource(imgSrc)
        setModel(true)
    }

    return (
        <>
            <div className={model ? "model open" : "model"}>
                <img src={tempImageSource} alt='zdjęcie' />
                <FaTimes className='close_btn' onClick={() => setModel(false)} />
            </div>
            <div className='gallery'>
                {
                    gallery.images.map((image, index) => (
                        <div className='gallery__image' key={index} onClick={() => getImage(image.imgSrc)}>
                            <img className='gallery__image__img' src={image.imgSrc} alt='zdjęcie' loading='lazy' />
                        </div>
                    ))
                }
            </div>
            <div className='fadeout'></div>
        </>
    )
}

export default Gallery;