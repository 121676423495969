import Navitem from "./Navitem/Navitem";
import { useRef } from 'react';
import { FaTimes, FaBars } from 'react-icons/fa'
import './navwrapper.scss';

const Navwrapper = () => {
    const navRef = useRef();

    const showNavbar = () => {
        navRef.current.classList.toggle("responsive_nav");
    }

    return(
        <>
            <ol ref={navRef} className='navwrapper'>
                <Navitem path='/' label='O NAS' />
                <Navitem path='/galeria' label='GALERIA' />
                <Navitem path='/kontakt' label='KONTAKT' />
                <button className='nav_btn nav_close_btn' onClick={showNavbar}>
                    <FaTimes />
                </button>
            </ol>
            <button className='nav_btn' onClick={showNavbar}>
                <FaBars />
            </button>
        </>
    )
}

export default Navwrapper;