import PropTypes from 'prop-types'
import './button.scss';

const Button = ({ children, path }) => (
    <button className='button collapsing'><a href={path} target='_blank' rel='noreferrer'>{children}</a></button>
)

Button.propTypes = {
    children: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired
}

export default Button;