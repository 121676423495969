import { Fade } from 'react-awesome-reveal'
import './hero.scss';

const Hero = () => {
    return(
        <div className='hero'>
            <div className='hero__overlay'></div>
            <Fade cascade duration={1800} triggerOnce direction='up'>
                <h1 className='hero__main_heading'>Chabrowa Chatka</h1>
                <h3 className='hero__subheading'>Spędźcie z nami super czas w górach</h3>
            </Fade>
        </div>
    )
}

export default Hero;