import { Fade } from 'react-awesome-reveal';
import './divider.scss'

const Divider = () => (
    <Fade triggerOnce duration={2000}>
        <div className='divider'>
            <div className='divider__overlay'></div>
        </div>   
    </Fade>     
)

export default Divider;