import { FaWifi, FaCar, FaTv, FaShower, FaSkiing, FaBed, FaWalking, FaGlassMartiniAlt } from "react-icons/fa";

const data = {
    info: {
        bookingLink: 'https://www.booking.com/hotel/pl/chabrowa-chatka-ustron.pl.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaLYBiAEBmAEeuAEXyAEM2AEB6AEB-AEMiAIBqAIDuAKgzYOiBsACAdICJDBjMmEyY2E4LTdiZDAtNDAxMy05MTAzLWVkYjcwOTg5MTE1NtgCBuACAQ&sid=7ec9af3158f35ba7838cbf0d6c5d63d3&dest_id=9006319;dest_type=hotel;dist=0;group_adults=2;group_children=0;hapos=1;hpos=1;no_rooms=1;req_adults=2;req_children=0;room1=A%2CA;sb_price_type=total;sr_order=popularity;srepoch=1681975013;srpvid=17c633328e4a0638;type=total;ucfs=1&#hotelTmpl',
        describtion: 'Chabrowa chatka to domek, zbudowany w góralskim stylu w całości z bali drewnianych, co tworzy niezwykły klimat. Jest to idealne miejsce na wyjazdy rodzinne, wypady ze znajomymi czy spotkania biznesowe. Parter chatki o powierzchni 31 metrów kwadratowych to pokój główny z rozkładaną sofą, dobrze wyposażony aneks kuchenny wraz z kącikiem do zjedzenia posiłków, łazienka z prysznicem i ubikacją oraz wyjście na taras. Piętro „w skosach” obejmuje dwie 3-osobowe sypialnie wraz z wnęką na ubrania. W jednej z sypialni znajduje się wyjście na balkonik. Chatka wyposażona jest w elektryczne ogrzewanie podłogowe. Ogrodzona działka to 7 arów wraz z 2 miejscami parkingowymi. Na tarasie znajduje się drewniany stół z ławami i dwoma krzesłami, duży parasol, a także grill. Chatka znajduje się przy niebieskim szlaku na Wielką Czantorię. Droga do dolnej stacji wyciągu narciarskiego Poniwiec to nie więcej niż 2,5km. W niedalekim sąsiedztwie przepływa cudowny potok Poniwiec, który u źródeł powstaje z połączenia potoków Gronika i Raztoki i jest lewym dopływem rzeki Wisły. Do centrum Ustronia (rynek główny) jest 2,5km.',
    },
    cards: [
        {
            icon: <FaWifi size={120} color='#303030' />,
            desc: 'Darmowe WiFi',
        },
        {
            icon: <FaBed size={120} color='#303030' />,
            desc: 'Dwie sypialnie',
        },
        {
            icon: <FaCar size={120} color="#303030" />,
            desc: 'Dwa miejsca parkingowe',
        },
        {
            icon: <FaGlassMartiniAlt size={120} color="#303030" />,
            desc: 'Dobrze wyposażona kuchnia',
        },
        {
            icon: <FaShower size={120} color='#303030' />,
            desc: 'Prywatna łazienka',
        },
        {
            icon: <FaWalking size={120} color='#303030' />,
            desc: 'Blisko szlaków turystycznych',
        },
        {
            icon: <FaSkiing size={120} color="#303030" />,
            desc: 'Niedaleko stoków',
        },
        {
            icon: <FaTv size={120} color="#303030" />,
            desc: 'Telewizja satelitarna',
        },
    ]
}

export default data;