import Statute from '../../assets/Regulamin.pdf'
import './footer.scss'

const Footer = () => (
    <footer className='footer'>
        <div className='info_wrapper'>
            <ul>
                <li><b>Adres</b></li>
                <li>ul. Lipowa 67a</li>
                <li>43-450 Ustroń</li>
            </ul>
            <ul>
                <li><b>Kontakt</b></li>
                <li>tel.: 609 319 276</li>
                <li>kontakt@chabrowachatka.com.pl</li>
            </ul>
            <ul>
                <li><b>Regulamin</b></li>
                <li><a href={Statute} target='_blank' rel='noreferrer'>regulamin</a></li>
                <li><a href={Statute} target='_blank' rel='noreferrer' download>pobierz regulamin</a></li>
            </ul>
        </div>
        <div className='copyrights'>©2023 Chabrowa Chatka | Wszelkie prawa zastrzeżone</div>
    </footer>
)

export default Footer;