import Navlogo from './Navlogo/Navlogo';
import Navwrapper from './NavWrapper/Navwrapper';
import Button from '../Button/Button';
import data from '../../data/data';
import './navbar.scss'

const Navbar = () => {

    return(
        <div className="navbar">
            <Navlogo />
            <Navwrapper />
            <Button path={data.info.bookingLink}>ZAREZERWUJ</Button>
        </div>
    )
}

export default Navbar;