import Card from './Card/Card';
import { Fade } from 'react-awesome-reveal';
import data from '../../../data/data';
import './sectioncardswrapper.scss'

const Sectioncardswrapper = () => (
    <div className='cardswrapper'>
        <Fade triggerOnce duration={2000} direction='up'>
            {data.cards.map(({ icon, desc }) => (
                <Card icon={icon} desc={desc} />
            ))}
        </Fade>
    </div>
)

export default Sectioncardswrapper;