import Navbar from '../../components/NavBar/Navbar'
import Section from '../../components/Section/Section'
import Sectionheading from '../../components/Section/SectionHeading/Sectionheading'
import Contactbox from '../../components/ContactBox/Contactbox'
import Footer from '../../components/Footer/Footer'

const Contact = () => (
    <>
        <Navbar />
        <Section>
            <Contactbox />
        </Section>
        <Footer />
    </>
)

export default Contact;