import Navbar from '../../components/NavBar/Navbar';
import Section from '../../components/Section/Section';
import SectionHeading from '../../components/Section/SectionHeading/Sectionheading'
import GalleryModule from '../../components/Gallery/Gallery';
import Footer from '../../components/Footer/Footer'

const Gallery = () => (
    <>
        <Navbar />
        <Section>
            <SectionHeading>Galeria zdjęć</SectionHeading>
            <GalleryModule />
        </Section>
        <Footer />
    </>
)

export default Gallery;