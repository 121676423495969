import Img1 from '../assets/gallery/20200805_120259G.webp'
import Img2 from '../assets/gallery/20200922_181353.webp'
import Img3 from '../assets/gallery/20201019_164016.webp'
import Img4 from '../assets/gallery/20211121_132430.webp'
import Img5 from '../assets/gallery/20220529_092346.webp'
import Img6 from '../assets/gallery/20220805_173023.webp'
import Img7 from '../assets/gallery/20220811_192238.webp'
import Img8 from '../assets/gallery/20220812_164254.webp'
import Img9 from '../assets/gallery/20221108_131750.webp'
import Img10 from '../assets/gallery/20221218_100327.webp'
import Img11 from '../assets/gallery/20221219_171926.webp'
import Img12 from '../assets/gallery/20221222_170603.webp'
import Img13 from '../assets/gallery/20221222_170740.webp'
import Img14 from '../assets/gallery/20230223_122924.webp' //
import Img15 from '../assets/gallery/DSC00716-2.webp'
import Img16 from '../assets/gallery/DSC00720-4.webp'
import Img17 from '../assets/gallery/DSC00745-7.webp'
import Img18 from '../assets/gallery/DSC00776-14.webp'
import Img19 from '../assets/gallery/DSC00820-20.webp'
import Img20 from '../assets/gallery/DSC00835-22.webp'
import Img21 from '../assets/gallery/DSC00843-24.webp'
import Img22 from '../assets/gallery/DSC00859-29.webp'
import Img23 from '../assets/gallery/DSC00866-31.webp'
import Img24 from '../assets/gallery/DSC00883-36.webp'
import Img25 from '../assets/gallery/DSC00889-37.webp'
import Img26 from '../assets/gallery/DSC00891-38.webp'
import Img27 from '../assets/gallery/DSC00894-40.webp'
import Img28 from '../assets/gallery/DSC00898-41.webp'
import Img29 from '../assets/gallery/DSC00900-42.webp' //
import Img30 from '../assets/gallery/DSC00902-43.webp'
import Img31 from '../assets/gallery/DSC00904-44.webp'
import Img32 from '../assets/gallery/DSC00918-49.webp'
import Img33 from '../assets/gallery/DSC00933-54.webp'
import Img34 from '../assets/gallery/DSC00939-55.webp'
import Img35 from '../assets/gallery/DSC00959-62.webp'
import Img36 from '../assets/gallery/DSC00964-65.webp'
import Img37 from '../assets/gallery/DSC00977-72.webp'
import Img38 from '../assets/gallery/IMG_20220811_215025_140.webp'
import Img39 from '../assets/gallery/IMG_20221008_172345_310.webp'
import Img40 from '../assets/gallery/IMG_20223124124.webp'

const gallery = {
    images: [
        {
            id: 1,
            imgSrc: Img1,
        },
        {
            id: 2,
            imgSrc: Img2,
        },
        {
            id: 3,
            imgSrc: Img3,
        },
        {
            id: 4,
            imgSrc: Img4,
        },
        {
            id: 5,
            imgSrc: Img5,
        },
        {
            id: 6,
            imgSrc: Img6,
        },
        {
            id: 7,
            imgSrc: Img7,
        },
        {
            id: 8,
            imgSrc: Img8,
        },
        {
            id: 9,
            imgSrc: Img9,
        },
        {
            id: 10,
            imgSrc: Img10,
        },
        {
            id: 11,
            imgSrc: Img11,
        },
        {
            id: 12,
            imgSrc: Img12,
        },
        {
            id: 13,
            imgSrc: Img13,
        },
        {
            id: 14,
            imgSrc: Img14,
        },
        {
            id: 15,
            imgSrc: Img15,
        },
        {
            id: 16,
            imgSrc: Img16,
        },
        {
            id: 17,
            imgSrc: Img17,
        },
        {
            id: 18,
            imgSrc: Img18,
        },
        {
            id: 19,
            imgSrc: Img19,
        },
        {
            id: 20,
            imgSrc: Img20,
        },
        {
            id: 21,
            imgSrc: Img21,
        },
        {
            id: 22,
            imgSrc: Img22,
        },
        {
            id: 23,
            imgSrc: Img23,
        },
        {
            id: 24,
            imgSrc: Img24,
        },
        {
            id: 25,
            imgSrc: Img25,
        },
        {
            id: 26,
            imgSrc: Img26,
        },
        {
            id: 27,
            imgSrc: Img27,
        },
        {
            id: 28,
            imgSrc: Img28,
        },
        {
            id: 29,
            imgSrc: Img29,
        },
        {
            id: 30,
            imgSrc: Img30,
        },
        {
            id: 31,
            imgSrc: Img31,
        },
        {
            id: 32,
            imgSrc: Img32,
        },
        {
            id: 33,
            imgSrc: Img33,
        },
        {
            id: 34,
            imgSrc: Img34,
        },
        {
            id: 35,
            imgSrc: Img35,
        },
        {
            id: 36,
            imgSrc: Img36,
        },
        {
            id: 37,
            imgSrc: Img37,
        },
        {
            id: 38,
            imgSrc: Img38,
        },
        // {
        //     id: 39,
        //     imgSrc: Img39,
        // },
        {
            id: 40,
            imgSrc: Img40,
        },
    ]
}

export default gallery;