import './contactbox.scss'

const Contactbox = () => (
    <>
    <div className='contactbox'>
        <div className='contactbox__column'>
            <div className='contactbox__column__item'>tel.: 609 319 276</div>
            <div className='contactbox__column__item'>kontakt@chabrowachatka.com.pl</div>
        </div>
        <div className='contactbox__column'>
            <div className='contactbox__column__item'>Booking.com: Chabrowa Chatka</div>
            <div className='contactbox__column__item'>Facebook: Chabrowa Chatka</div>
        </div>
    </div>
    <iframe title='map' className='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2580.4197741607927!2d18.811627876618267!3d49.70289847145906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47141bd89acd9dfb%3A0x19c846cf07b9fcce!2sChabrowa%20Chatka!5e0!3m2!1spl!2spl!4v1682598850804!5m2!1spl!2spl" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </>
)

export default Contactbox