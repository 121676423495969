import Navbar from '../../components/NavBar/Navbar';
import Hero from '../../components/Hero/Hero';
import Section from '../../components/Section/Section';
import Sectionheading from '../../components/Section/SectionHeading/Sectionheading';
import Sectionparagraph from '../../components/Section/SectionParagraph/Sectionparagraph';
import Sectioncardswrapper from '../../components/Section/SectionCardsWrapper/Sectioncardswrapper';
import Divider from '../../components/Divider/Divider';
import Footer from '../../components/Footer/Footer';
import data from '../../data/data';

const Main = () => (
    <>
        <Navbar />
        <Hero />
        <Section>
            <Sectionheading>Kilka słów o nas</Sectionheading>
            <Sectionparagraph>{data.info.describtion}</Sectionparagraph>
        </Section>
        <Divider />
        <Section>
            <Sectionheading>Udogodnienia</Sectionheading>
            <Sectioncardswrapper />
        </Section>
        <Footer />
    </>
)

export default Main;